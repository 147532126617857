import React from 'react'
import { useWowAnimation } from '../../hooks/useWowAnimation';

/* =========================   Contact-us Section    =======================*/

const ContactUsPg = () => {
    useWowAnimation();

    return (
        // <!-- Start  contact us Section-->
        <div>
            <section className="contact-us  mega-section  pb-0" id="contact-us">
                <div className="container">
                    <section className="contact-us-form-section  mega-section  ">
                        <div className="row">
                            <div className="col-12 ">
                                <div className="contact-form-panel">
                                    <div className="sec-heading centered    ">
                                        <div className="content-area">
                                            <h2 className="title    wow fadeInUp" data-wow-delay=".4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>Have any questions? Let's answer them</h2>
                                        </div>
                                    </div>
                                    <div className="contact-form-inputs wow fadeInUp" data-wow-delay=".6s" style={{ visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInUp' }}>
                                        <div className="custom-form-area input-boxed">
                                            {/* <!--Form To have user messages--> */}
                                            <form className="main-form" id="contact-us-form" action="form/contact.php" method="post"><span className="done-msg"></span>
                                                <div className="row ">
                                                    <div className="col-12 col-lg-6">
                                                        <div className="input-wrapper">
                                                            <input className="text-input" id="user-name" name="UserName" type="text" />
                                                            <label className="input-label" for="user-name"> Name <span className="req">*</span></label><span className="b-border"></span><span className="error-msg"></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="input-wrapper">
                                                            <input className="text-input" id="user-email" name="UserEmail" type="email" />
                                                            <label className="input-label" for="user-email"> E-mail <span className="req">*</span></label><span className="b-border"></span><span className="error-msg">
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 ">
                                                        <div className="input-wrapper">
                                                            <input className="text-input" id="msg-subject" name="subject" type="text" />
                                                            <label className="input-label" for="msg-subject"> Subject <span className="req">*</span></label><span className="b-border"></span><span className="error-msg"></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 ">
                                                        <div className="input-wrapper">
                                                            <textarea className=" text-input" id="msg-text" name="message"></textarea>
                                                            <label className="input-label" for="msg-text"> your message <span className="req">*</span></label><span className="b-border"></span><i></i><span className="error-msg"></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 submit-wrapper">
                                                        <button className=" btn-solid" id="submit-btn" type="submit" name="UserSubmit">Send your message</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="locations-section  mega-section ">
                        <div className="sec-heading centered  ">
                            <div className="content-area">
                                <h2 className="title    wow fadeInUp" data-wow-delay=".4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>our offices around the world</h2>
                            </div>
                        </div>
                        <div className=" contact-info-panel ">
                            <div className="info-section ">
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <div className="info-panel  wow fadeInUp" data-wow-delay=".4s " style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>
                                            <h4 className="location-title">India</h4>
                                            <div className="line-on-side "> </div>
                                            <p className="location-address">501, 5th Floor, Shapath 1, SG Highway, Bodakdev, Ahmadabad City, Ahmedabad- 380054, Gujarat</p>
                                            <div className="location-card  "><i className="bi bi-envelope-fill icon"></i>
                                                <div className="card-content">
                                                    <h6 className="content-title">email:</h6><a className="email link" href="mailto:info@xerotag.com">info@xerotag.com</a>
                                                </div>
                                            </div>
                                            <div className="location-card  "><i className="bi bi-telephone icon"></i>
                                                <div className="card-content">
                                                    <h6 className="content-title">phone:</h6><a className="tel link" href="tel:+917874737265">+91 7874737265</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="info-panel  wow fadeInUp" data-wow-delay=".6s" style={{ visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInUp' }}>
                                            <h4 className="location-title">United Kingdom</h4>
                                            <div className="line-on-side "> </div>
                                            <p className="location-address">5, orchardson avenue, Peepul centre, Leicester, England, le4 6dp.</p>
                                            <div className="location-card  "><i className="bi bi-envelope-fill icon"></i>
                                                <div className="card-content">
                                                    <h6 className="content-title">email:</h6><a className="email link" href="mailto:info@xerotag.com">info@xerotag.com</a>
                                                </div>
                                            </div>
                                            <div className="location-card  "><i className="bi bi-telephone icon"></i>
                                                <div className="card-content">
                                                    <h6 className="content-title">phone:</h6><a className="tel link" href="tel:+44 7751 671462">+44 7751 671462</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="info-panel  wow fadeInUp" data-wow-delay=".8s" style={{ visibility: 'visible', animationDelay: '0.8s', animationName: 'fadeInUp' }}>
                                            <h4 className="location-title">Africa</h4>
                                            <div className="line-on-side "> </div>
                                            <p className="location-address">Kaizen Services
                                                Buea, cameroon</p>
                                            <div className="location-card  "><i className="bi bi-envelope-fill icon"></i>
                                                <div className="card-content">
                                                    <h6 className="content-title">email:</h6><a className="email link" href="mailto:info@xerotag.com">info@xerotag.com</a>
                                                </div>
                                            </div>
                                            <div className="location-card  "><i className="bi bi-telephone icon"></i>
                                                <div className="card-content">
                                                    <h6 className="content-title">phone:</h6><a className="tel link" href="tel:+459876543210">+459876543210</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
        // {/* // <!-- End  contact us Section--> */}
    )
}
export default ContactUsPg;
