import React, { useEffect, useState } from 'react';
import { scrollToSection } from '../main';


const Footer = () => {

  const [showTopUpBtn, setShowTopUpBtn] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setShowTopUpBtn(true);
      } else {
        setShowTopUpBtn(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    
    <div>
      {/* <!-- Start  page-footer Section--> */}
    <div className="page-footer dark-color-footer" id="page-footer">
      <div className="overlay-photo-image-bg" data-bg-img="footer-bg-1.jpg" data-bg-opacity=".25" /*style="background-image: url(&quot;assets/images/sections-bg-images/footer-bg-1.jpg&quot;); opacity: 0.25;"*/ ></div>
      <div className="container">
        <div className="row footer-cols">
          <div className="col-12 col-md-8 col-lg-4  footer-col ">
            <img src={"/image/logo-light.png"} className="img-fluid footer-logo" loading="lazy"  alt="logo" />
            <div className="footer-col-content-wrapper">        
              <p className="footer-text-about-us ">
                At Xerotag We Pride Ourselves On Being More Than Just An IT Solutions Provider, We Are Your Strategic Partner In Navigating The Digital Landscape.  
              </p>
            </div>
            <div className="form-area ">
              {/* <!--<div className="mailchimp-form ">
                <form className="one-field-form" method="post" action="">
                  <div className="field-group ">
                    <label className="email-label" for="email-input"> Subscribe to our news letter</label>
                    <input className="email-input " type="email" value="" name="EMAIL" id="email-input" placeholder="Email Address" autocomplete="off">
                    <div className="cta-area">
                      <input className="btn-solid subscribe-btn" type="submit" value="Subscribe" name="subscribe">
                    </div>
                  </div><span className="email-notice">*we will not share your personal info</span>
                </form>
              </div>--> */}
            </div>
          </div>
          <div className="col-6 col-lg-2  footer-col ">
           {/* <!-- <h2 className=" footer-col-title    ">useful links</h2>
            <div className="footer-col-content-wrapper">
              <ul className="footer-menu ">
                <li className="footer-menu-item"><i className="bi bi-arrow-right icon "></i><a className="footer-menu-link" href="https://g.co/kgs/JQTg4jA">Google</a>
                </li>
                <li className="footer-menu-item"><i className="bi bi-arrow-right icon "></i><a className="footer-menu-link" href="https://xerotag.com">Dribbble</a>
                </li>
                <li className="footer-menu-item"><i className="bi bi-arrow-right icon "></i><a className="footer-menu-link" href="https://www.linkedin.com/company/xerotag/">linkedIn</a>
                </li>
                <li className="footer-menu-item"><i className="bi bi-arrow-right icon "></i><a className="footer-menu-link" href="https://xerotag.com">wikipedia</a>
                </li>
              </ul>
            </div>--> */}
          </div>
          <div className="col-6 col-lg-2 footer-col ">
            {/* <!--<h2 className=" footer-col-title    ">Resources</h2>
            <div className="footer-col-content-wrapper">
              <ul className="footer-menu">
                <li className="footer-menu-item"><i className="bi bi-arrow-right icon "></i><a className="footer-menu-link" href="https://xerotag.com">support</a>
                </li>
                <li className="footer-menu-item"><i className="bi bi-arrow-right icon "></i><a className="footer-menu-link" href="https://xerotag.com">dashboard</a>
                </li>
                <li className="footer-menu-item"><i className="bi bi-arrow-right icon "></i><a className="footer-menu-link" href="https://xerotag.com">drivers</a>
                </li>
                <li className="footer-menu-item"><i className="bi bi-arrow-right icon "></i><a className="footer-menu-link" href="https://xerotag.com">projects</a>
                </li>
              </ul>
            </div>--> */}
          </div>
          <div className="col-12 col-lg-4 footer-col">
            <h2 className=" footer-col-title    ">contact information</h2>
            <div className="footer-col-content-wrapper">       
              <div className="contact-info-card"><i className="bi bi-envelope icon"></i><a className="text-lowercase  info" href="mailto:info@xerotag.com"> info@xerotag.com</a></div>
              <div className="contact-info-card"><i className="bi bi-geo-alt icon"></i><span className="text-lowercase  info"> 5, Orchardson Avenue, Peepul Centre,<br/> Leicester, England, LE4 6DP
                {/* TO DO : check the br */}
                 {/* <br>Leicester, England, LE4 6DP,</br> */}
                 </span></div>
              <div className="contact-info-card"><i className="bi bi-phone icon"></i><a className="info" href="tel:+44 7751 671462" style={{textDecoration: 'none', color:'white', cursor: 'pointer'}}> +44 7751 671462</a></div>
              <div className="contact-info-card">
                <div className="social-icons">
                  <div className="sc-wrapper dir-row sc-size-32">
                    <ul className="sc-list" >
                      <li className="sc-item" title="Facebook"><a className="sc-link" href="https://www.facebook.com/xerotag" title="social media icon" target='blank'><i className="facebook bi bi-facebook icon" style={{color: "#fff",display: "contents"}}></i></a></li>
                      <li className="sc-item" title="youtube"><a className="sc-link" href="https://youtube.com" title="social media icon" target='blank'><i className="youtube bi bi-youtube icon  " style={{color: "#fff", display: "contents"}}></i></a></li>
                      <li className="sc-item" title="instagram"><a className="sc-link" href="https://www.instagram.com/xerotag/" title="bi social media icon" target='blank'><i className="instagram bi bi-instagram icon " style={{color: "#fff", display: "contents"}}></i></a></li>
                      <li className="sc-item" title="x"><a className="sc-link" href="https://x.com/" title="social media icon" target='blank'><i className="twitter-xbi bi-twitter-x icon" style={{color: "#fff", display: "contents"}}></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights ">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 d-flex justify-content-start">
              <p className="creadits">
                Team <a className="link" href="https://xerotag.com/">Xerotag © 2025 | Transforming IT together</a>
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-end">
              <div className="terms-links"><a href="https://xerotag.com">Terms of Use </a>
                | <a href="https://xerotag.com">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End  page-footer Section */}

    {/* <!-- Start loading-screen Component--> */}
    {/* <!-- End loading-screen Component--> */}
    {/* <!-- Start back-to-top Component--> */}
    <div className={`back-to-top ${showTopUpBtn ? 'show' : ''}`} id="back-to-top" onClick={() => scrollToSection('page-hero')}><i className="bi bi-arrow-up icon "></i>
    </div>
    {/* <!-- End back-to-top Component-->    */}
        
    </div>

    
  )
}

export default Footer
